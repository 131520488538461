/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const Terms = props => {
  return (
    <Layout>
      <SEO defaultName={props.data.wpNotice.title} />
      <div className="grid-container gap-xxxl" sx={{ paddingTop: `200px` }}>
        <h1>Terms of Use</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: props.data.wpNotice.notices.content,
          }}
        ></div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    wpNotice(title: { eq: "Terms of Use" }) {
      title
      notices {
        content
      }
    }
  }
`
export default Terms
